<template>
  <div class="dialog-overlay" 
    :class="[show ? 'opened-dialog' : 'closed-dialog']">
    <div class="dialog-container-sm">
      <v-card flat id="warning-card" class="my-card">
        <v-card-text>
          <div class="warning-content">
            {{$t('canceledSub')}}
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'SubCanceledModal',
    props: {
     visible: Boolean,
     actionType: String
    },
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      }
    }
  }
</script>

