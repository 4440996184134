<template>
  <div id="billing-tab">
    <v-data-table
      id="billing-table"
      :headers="headers"
      :items="getCardInfo"
      :items-per-page="5"
      item-key="date"
      class="my-table"
    >
      <template v-slot:item.edit="{ item }">
        <div class="d-flex justify-start">
          <div>
            <v-img
              contain
              height="24"
              width="24"
              color="#4E4B66"
              src="@/assets/edit.svg"
              class="hover-action mr-8"
              @click="goToEditCard"
            ></v-img>
          </div>
        </div>
      </template>
    </v-data-table>

    <v-row class="pb-4" v-if="isSubscriptionActive">
      <v-col cols="2" offset="5">
        <v-btn
          color="#eaac30"
          class="my-btn"
          height="56"
          depressed
          block
          @click="cancelSubscription()"
        >
          {{$t('cancelSubscription')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-overlay :value="showLoader">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import axios from "axios";

  export default {
    name: 'BillingTab',
    computed: {
      ...mapGetters(["cardInfo", "subscription", "isSubscriptionActive"]),
      headers() {
        return [
          { text: this.$t('cardNumber'), value: 'card_number', sortable: false },
          { text: this.$t('expiringDate'), value: 'expiry_date', sortable: false },
          { text: this.$t('plan'), value: 'plan', sortable: false },
          { text: this.$t('editCard'), value: 'edit', sortable: false, width: '15%' }
        ]
      },
      getCardInfo() {
        let result = [];

        if (this.cardInfo != null && Object.keys(this.cardInfo).length != 0) {
          let month = this.addZero(this.cardInfo.exp_month);
          let year = this.cardInfo.exp_year;
          let exp_date = month + '/' + year;
          let plan = this.subscription.plan_interval + '_plan';

          result.push({
            card_number: '************' + this.cardInfo.last4,
            expiry_date: exp_date,
            plan: this.$t(plan)
          });
        }
        return result;
      }
    },
    data: () => ({
      showLoader: false,
      appHomeUrl: process.env.VUE_APP_HOME_URL
    }),
    methods: {
      addZero(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      },
      goToEditCard() {
        this.showLoader = true;
        let url = `${process.env.VUE_APP_BASE_URL}/api/change-pay-session`;
        axios.post(url, {
          success_url: this.appHomeUrl,
          cancel_url: this.appHomeUrl
        }).then(res => {
          window.location.assign(res.data.checkout);
        }).catch(er => {
          console.log(er);
        });
      },
      cancelSubscription() {
        this.$emit('cancel-sub');
      }
    }
  }
</script>

<style type="text/css">
  #billing-table table thead tr th:first-child,
  #billing-table table tbody tr td:first-child {
    padding-left: 40px;
  }

  #billing-table table thead tr th:last-child,
  #billing-table table tbody tr td:last-child {
    padding-right: 40px;
  }

  #billing-table .v-data-footer .v-data-footer__pagination,
  #billing-table .v-data-footer .v-data-footer__icons-before,
  #billing-table .v-data-footer .v-data-footer__icons-after {
    visibility: hidden;
  }

  @media screen and (max-width: 600px) {
    #billing-table table thead {
      display: none;
    }

    #billing-table table thead tr th:first-child,
    #billing-table table tbody tr td:first-child {
      padding: 0 16px;
    }

    #billing-table table tbody tr td:last-child {
      padding-right: 16px;
    }
  }
</style>