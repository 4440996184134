<template>
  <div id="user-tab">
    <v-data-table
      id="user-table"
      :headers="headers"
      :items="getUser"
      :items-per-page="5"
      item-key="date"
      class="my-table"
    >
      <template v-slot:item.initials="{ item }">
        <v-avatar
          color="#EFF0F6"
          size="42"
          class="mr-5"
        >
          <span class="user-initials">{{ item.initials }}</span>
        </v-avatar>
      </template>

      <template v-slot:item.fullname="{ item }">
        {{item.fullname}}
      </template>

      <template v-slot:item.edit="{ item }">
        <div class="d-flex justify-start">
          <div>
            <v-img
              contain
              height="24"
              width="24"
              color="#4E4B66"
              src="@/assets/edit.svg"
              class="hover-action mr-8"
              @click="editUser"
            ></v-img>
          </div>
        </div>
      </template>
    </v-data-table>

    <v-overlay :value="showLoader">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'UserTab',
    computed: {
      ...mapGetters(["user"]),
      headers() {
        return [
          { text: '', value: 'initials', sortable: false, width: '3%' },
          { text: this.$t('fullname'), value: 'fullname', sortable: false },
          { text: this.$t('edit'), value: 'edit', sortable: false, width: '2%' }
        ]
      },
      getUser() {
        let initials = '';
        let fullname = '';
        let result = [];

        let first = '';
        let second = '';
        if (Object.keys(this.user).length != 0) {
          first = this.user.firstname.slice(0, 1);
          second = this.user.lastname.slice(0, 1);
          fullname = this.user.firstname + ' ' + this.user.lastname;
        }
        initials = first + second;
        result.push({
          fullname: fullname,
          initials: initials.toUpperCase()
        });

        return result;
      }
    },
    data: () => ({
      showLoader: false
    }),
    methods: {
      editUser() {
        this.$emit('open-edit-user-modal');
      }
    }
  }
</script>

<style type="text/css">
  .user-initials {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
  }

  #user-table table thead tr th:first-child,
  #user-table table tbody tr td:first-child {
    padding-left: 40px;
  }

  #user-table table thead tr th:last-child,
  #user-table table tbody tr td:last-child {
    padding-right: 40px;
  }

  #user-table .v-data-footer .v-data-footer__pagination,
  #user-table .v-data-footer .v-data-footer__icons-before,
  #user-table .v-data-footer .v-data-footer__icons-after {
    visibility: hidden;
  }

  @media screen and (max-width: 600px) {
    #user-table table thead {
      display: none;
    }

    #user-table table tr.v-data-table__mobile-table-row {
      padding: 8px 0;
    }

    #user-table table thead tr th:first-child,
    #user-table table tbody tr td:first-child {
      padding-left: 24px;
    }

    #user-table table thead tr th:last-child,
    #user-table table tbody tr td:last-child {
      padding-right: 24px;
    }
  }
</style>