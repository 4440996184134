<template>
  <div class="my-modal-overlay" 
    :class="[show ? 'opened-dialog' : 'closed-dialog']">
    <div id="edit-user-modal" class="dialog-container-center">
      <v-card flat id="edit-user-card" class="my-card">
        <v-form @submit.prevent="saveUserAction" novalidate>
          <v-container>
            <v-row>
              <v-col class="d-flex align-center">
                <div class="pr-2">
                  <v-img
                    contain
                    height="24"
                    width="24"
                    class=""
                    src="@/assets/profile.svg"
                  ></v-img>
                </div>
                <div>
                  <h2>{{$t("user")}}</h2>
                </div>
              </v-col>
              <v-col></v-col>
              <v-col class="text-right">
                <div>
                  <v-avatar
                    color="#EFF0F6"
                    size="68"
                  >
                    <span class="avatar-initials">{{getUserInitials}}</span>
                  </v-avatar>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-4 mt-md-8">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :label="$t('name')"
                  v-model="firstname"
                  filled
                  flat
                  color="#262338"
                  background-color="#f5f7fa"
                  height="55"
                  class="my-text-field"
                  :error-messages="firstNameErrors"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="my-order-4 py-0">
                <v-text-field
                  :label="$t('currentPassword')"
                  v-model="currentPassword"
                  type="password"
                  filled
                  flat
                  color="#262338"
                  background-color="#f5f7fa"
                  height="55"
                  class="my-text-field"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :label="$t('surname')"
                  v-model="lastname"
                  filled
                  flat
                  color="#262338"
                  background-color="#f5f7fa"
                  height="55"
                  class="my-text-field"
                  :error-messages="lastNameErrors"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="my-order-5 py-0">
                <v-text-field
                  :label="$t('newPassword')"
                  v-model="newPassword"
                  type="password"
                  filled
                  flat
                  color="#262338"
                  background-color="#f5f7fa"
                  height="55"
                  class="my-text-field"
                  :error-messages="newPasswordErrors"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :label="$t('email')"
                  v-model="email"
                  filled
                  flat
                  color="#262338"
                  background-color="#f5f7fa"
                  height="55"
                  class="my-text-field"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="my-order-6 py-0">
                <v-text-field
                  :label="$t('confirmNewPassword')"
                  v-model="confirmPassword"
                  type="password"
                  filled
                  flat
                  color="#262338"
                  background-color="#f5f7fa"
                  height="55"
                  class="my-text-field"
                  :error-messages="confirmPasswordErrors"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-card-actions class="my-card-actions">
              <v-row>
                <v-col md="2" lg="3" class="d-none d-md-block"></v-col>
                <v-col cols="12" sm>
                  <v-btn
                    type="button"
                    height="54px"
                    outlined
                    block
                    class="my-btn red-btn"
                    @click="cancelBtnAction"
                  >
                    {{$t("cancel")}}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm>
                  <v-btn
                    type="submit"
                    height="54px"
                    depressed
                    block
                    dark
                    class="my-btn save-btn"
                  >
                    {{$t("save")}}
                    <v-spacer></v-spacer>
                    <v-icon>{{check_icon}}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import { validationMixin } from "vuelidate";
  import { required, sameAs, minLength} from "vuelidate/lib/validators";

  export default {
    name: 'EditUserModal',
    mixins: [validationMixin],
    props: {
     visible: Boolean
    },
    validations: {
      firstname: { required },
      lastname: { required },
      newPassword: { minLength: minLength(6) },
      confirmPassword: { sameAsPassword: sameAs("newPassword") }
    },
    computed: {
      ...mapGetters(["user", "editUser"]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      getUserInitials() {
        let result = '';
        let first = '';
        let second = '';
        if (Object.keys(this.user).length != 0) {
          first = this.user.firstname.slice(0, 1);
          second = this.user.lastname.slice(0, 1);
        }
        result = first + second;
        return result;
      },
      firstname: {
        get () {
          return this.$store.state.profile.editUser.firstname;
        },
        set(value) {
          let payload = {
            prop: 'firstname',
            value: value
          }
          this.$store.commit('editUserProp', payload);
        }
      },
      lastname: {
        get () {
          return this.$store.state.profile.editUser.lastname;
        },
        set(value) {
          let payload = {
            prop: 'lastname',
            value: value
          }
          this.$store.commit('editUserProp', payload);
        }
      },
      email: {
        get () {
          return this.$store.state.profile.editUser.email;
        },
        set(value) {
          this.$store.commit('editUserProp', payload);
        }
      },
      currentPassword: {
        get () {
          return this.$store.state.profile.currentPassword;
        },
        set(value) {
          this.$store.commit('setCurrentPassword', value);
        }
      },
      newPassword: {
        get () {
          return this.$store.state.profile.newPassword;
        },
        set(value) {
          this.$store.commit('setNewPassword', value);
        }
      },
      confirmPassword: {
        get () {
          return this.$store.state.profile.confirmPassword;
        },
        set(value) {
          this.$store.commit('setConfirmPassword', value);
        }
      },
      firstNameErrors() {
        const errors = [];
        if (!this.$v.firstname.$dirty) return errors;
        if (!this.$v.firstname.required) errors.push(this.$t("profileErr1"));
        return errors;
      },
      lastNameErrors() {
        const errors = [];
        if (!this.$v.lastname.$dirty) return errors;
        if (!this.$v.lastname.required) errors.push(this.$t("profileErr2"));
        return errors;
      },
      hasProfileChanges() {
        let oldFirstname = this.user.firstname;
        let oldLastname = this.user.lastname;

        let newFirstname = this.editUser.firstname;
        let newLastname = this.editUser.lastname;

        return (oldFirstname != newFirstname) || (oldLastname != newLastname);
      },
      newPasswordErrors() {
        const errors = [];
        if (!this.$v.newPassword.$dirty) return errors;
        if (!this.$v.newPassword.minLength) errors.push(this.$t("accountErr3"));
        return errors;
      },
      confirmPasswordErrors() {
        const errors = [];
        if (!this.$v.confirmPassword.$dirty) return errors;
        if (!this.$v.confirmPassword.sameAsPassword) errors.push(this.$t("accountErr5"));
        return errors;
      },
      requestsPasswordChange() {
        return this.currentPassword != '' && this.newPassword != '' && this.confirmPassword != '';
      }
    },
    data: () => ({
      check_icon: "$vuetify.icons.check_icon"
    }),
    methods: {
      ...mapActions([]),
      saveUserAction() {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        if (this.hasProfileChanges && !this.requestsPasswordChange) {
          let payload = {
            firstname: this.firstname,
            lastname: this.lastname
          }
          this.$emit('update-profile', payload);
        } else if (this.requestsPasswordChange && !this.hasProfileChanges) {
          let payload = {
            current: this.currentPassword,
            password: this.newPassword
          }
          this.$emit('password-change', payload);
        } else if(this.hasProfileChanges && this.requestsPasswordChange) {
          let payload = {
            firstname: this.firstname,
            lastname: this.lastname,
            current: this.currentPassword,
            password: this.newPassword
          }
          this.$emit('update-profile-and-password-change', payload);
        } else {
          this.currentPassword = '';
          this.newPassword = '';
          this.confirmPassword = '';
          this.$emit('close');
        }
      },
      cancelBtnAction() {
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.$emit('close');
      }
    }
  }
</script>

<style type="text/css">
  #edit-user-card {
    padding-top: 40px;
    position: relative;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  #edit-user-card.my-card .my-select-box {
    padding: 0;
  }

   #edit-user-card.my-card .my-text-field.v-text-field > .v-input__control > .v-input__slot::before,
   #edit-user-card.my-card .my-text-field.v-text-field > .v-input__control > .v-input__slot::after {
    border: none;
   }

  #edit-user-card.my-card .my-select-box .v-label,
  #edit-user-card.my-card .my-select-box input,
  #edit-user-card.my-card .my-text-field .v-label,
  #edit-user-card.my-card .my-text-field input {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.75px;
    color: #262338 !important;
  }

  #edit-user-card .my-btn.save-btn.v-btn--disabled,
  #edit-user-card .my-btn.save-btn.v-btn--disabled .v-icon {
    color: #03CFBB !important;
  }

  .apiary-list-item {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #262338;
  }

  #edit-user-card .my-card-actions {
    margin-top: 75px;
    padding: 0;
  }

  #edit-user-card .my-text-field.v-input--is-disabled {
    opacity: 0.7;
  }

  @media screen and (max-width: 960px) {
    .my-order-4 {
      order: 4;
    }

    .my-order-5 {
      order: 5;
    }

    .my-order-6 {
      order: 6;
    }

    #edit-user-modal.dialog-container-center {
      padding: 0;
      width: 100%;
      left: 0;
    }

    #edit-user-card.my-card {
      border-radius: 0;
    }
  }

  @media screen and (max-width: 600px) {
    #edit-user-card.my-card {
      padding: 20px 25px 70px;
    }

    #edit-user-card .my-card-actions {
      margin-top: 20px;
    }
  }
</style>
