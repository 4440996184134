<template>
  <div id="settings-page">
    <v-tabs
      grow
      hide-slider
      class="settings-tabs"
      background-color="#F7F7FC"
      color="#262338"
      v-model="activeTab"
    >
      <v-tab v-for="(tab, index) in tabs" :key="'tab-'+index"
        class="my-tab"
        :class="{'active-tab': tab.active}"
        @change="updateTab(index)"
      >
        <v-img
          v-if="tab.active"
          contain
          height="24"
          width="24"
          class="tab-icon"
          :src="`${publicPath}img/icons/`+tab.icon+'_active.svg'"
        ></v-img>
        <v-img
          v-else
          contain
          height="24"
          width="24"
          class="tab-icon"
          :src="`${publicPath}img/icons/`+tab.icon+'_inactive.svg'"
        ></v-img>
        {{$t(tab.name)}}
      </v-tab>
      <v-tabs-items v-model="activeTab" touchless>
        <v-tab-item
          v-for="(tab, index) in tabs"
          :key="'tab-content-'+index"
        >
          <component
            v-bind:is="tab.component"
            @open-edit-user-modal="openEditUserModal"
            @cancel-sub="showConfirmCancelSubModal = true"
          ></component>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <EditUserModal
      :visible="showEditUserModal"
      @close="closeEditUserModal"
      @update-profile="updateProfile"
      @password-change="passwordChange"
      @update-profile-and-password-change="updateUser"
    />

    <ConfirmCancelSubModal
      :visible="showConfirmCancelSubModal"
      @close="showConfirmCancelSubModal = false"
      @proceed="cancelSubscription"
    />

    <SubCanceledModal
      :visible="showSubCanceledModal"
      @close="showSubCanceledModal = false"
    />


    <v-overlay :value="showLoader" z-index=11>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import axios from "axios";

  import UserTab from '@/components/SettingsPage/UserTab'
  import BillingTab from '@/components/SettingsPage/BillingTab'
  import EditUserModal from '@/components/SettingsPage/EditUserModal'
  import ConfirmCancelSubModal from '@/components/SettingsPage/ConfirmCancelSubModal'
  import SubCanceledModal from '@/components/SettingsPage/SubCanceledModal'

  export default {
    name: 'Settings',
    components: {
      'user-tab': UserTab,
      'billing-tab': BillingTab,
      EditUserModal,
      ConfirmCancelSubModal,
      SubCanceledModal
    },
    computed: {
      ...mapGetters(["user", "settingsActiveTab"]),
      activeTab: {
        get () {
          let activeTab = this.$store.state.profile.settingsActiveTab;
          this.updateTab(activeTab);
          return activeTab;
        },
        set(value) {
          this.updateTab(value);
          this.$store.commit('setSettingsActiveTab', value);
        }
      }
    },
    data: () => ({
      showLoader: false,
      tabs: [
        {active: true, icon: 'profile', name: 'user', component: 'user-tab'},
        {active: false, icon: 'card', name: 'billing', component: 'billing-tab'}
      ],
      publicPath: process.env.BASE_URL,
      showEditUserModal: false,
      showConfirmCancelSubModal: false,
      showSubCanceledModal: false
    }),
    mounted() {
      this.setIsHiddenApiaryButton(true);
      this.showLoader = true;
      this.getCardInfo().then(() => {
        this.showLoader = false;
      });
    },
    methods: {
      ...mapActions([
        "setIsHiddenApiaryButton",
        "setEditUser",
        "resetEditUser",
        "editProfile",
        "changePassword",
        "getCardInfo",
        "logout"
      ]),
      updateTab(index) {
        for (let k = 0; k < this.tabs.length; k++) {
          this.tabs[k].active = false;
        }
        this.tabs[index].active = true;
      },
      updateProfile(payload) {
        console.log('updateProfile');
        this.showLoader = true;
        this.editProfile(payload).then(() => {
          this.showLoader = false;
          this.closeEditUserModal();
        }).catch(() => {
          this.showLoader = false;
        });
      },
      passwordChange(payload) {
        console.log('passwordChange');
        this.showLoader = true;
        this.changePassword(payload).then(() => {
          this.showLoader = false;
          this.closeEditUserModal();
        }).catch(() => {
          this.showLoader = false;
        });
      },
      updateUser(payload) {
        console.log('updateUser');
        this.showLoader = true;
        this.editProfile({
          firstname: payload.firstname,
          lastname: payload.lastname,
        }).then(() => {
          this.changePassword({
            current: payload.current,
            password: payload.password
          }).then(() => {
            this.showLoader = false;
            this.closeEditUserModal();
          }).catch(() => {
            this.showLoader = false;
          });
        }).catch(() => {
          this.showLoader = false;
        });
      },
      openEditUserModal() {
        this.showLoader = true;

        let userData = JSON.parse(JSON.stringify(this.user));
        this.setEditUser(userData).then(() => {
          this.showEditUserModal = true;
          document.body.classList.add("modal-open");
          this.showLoader = false;
        })
      },
      closeEditUserModal() {
        this.resetEditUser();
        this.showEditUserModal = false;
        document.body.classList.remove("modal-open");
      },
      cancelSubscription() {
        this.showConfirmCancelSubModal = false;
        this.showLoader = true;
        let url = `${process.env.VUE_APP_BASE_URL}/api/cancel-sub`;
        axios.post(url, {}).then(res => {
          this.showSubCanceledModal = true;
          this.showLoader = false;
          setTimeout(() => {
            this.showSubCanceledModal = false;
            this.logout();
            this.$router.go("login");
          }, 5000);
        }).catch(er => {
          console.log(er);
        });
      }
    }
  }
</script>

<style type="text/css">

</style>
